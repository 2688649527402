<template>
  <pibot-settings-users-list />
</template>

<script>
export default {
  name: 'pibot-settings-super-admin',
  components: {
    'pibot-settings-users-list': () => import('@/components/pages/settings/UsersList')
  },
  beforeRouteEnter (to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next(vm => {
      if (vm.$me.role === 'superadmin') next()
      else vm.$router.push('/not-found')
    })
  }
}
</script>
